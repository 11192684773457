@use "../../../../../base/Resources/Public/Scss/includes/core" as core;

// page-footer sollte auf allen Seiten sichtbar sein
// showcase-url: https://go-e.local/de-at/showcase/build/modules/goe/page-footer.html

#page-footer {
  > .base-padding-v--big {
    --padding-v-space: var(--space-huge);
  }
}

.page-footer {
  @include core.named-breakpoint(medium, down) {
    --color-link: var(--color-main);
  }
  @include core.named-breakpoint(large) {
    font-size: var(--font-size-small);
    display: grid;
    grid-template-rows: auto;
  }

  .page-footer-title {
    color: var(--color-contrast);
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    font-size: 1.1rem;
    padding: 0.8rem 0.6rem 0.8rem 0;
    border-top: 1px solid var(--color-link);

    @include core.named-breakpoint(large) {
      padding: 0;
      font-size: 0.9rem;
      border-top: none;
    }
  }

  address {
    font-style: normal;
  }

  base-slide-content {
    @include core.named-breakpoint(large) {
      display: block!important;
      height: auto!important;
    }
  }

  .page-footer__logo-and-iconlinks {
    margin-bottom: var(--space-huge);
    padding-right: var(--space-regular);

    @include core.named-breakpoint(medium) {
      display: flex;
      justify-content: space-between;
    }
    @include core.named-breakpoint(large) {
      width: max-content;
      padding-right: var(--space-big);
      margin-bottom: 0;
      grid-column-end: span 1;
      grid-row-end: 1;
      display: grid;
      section {
        align-self: end;
      }
    }
    .base-icon-links {
      gap: 1rem;
      @include core.named-breakpoint(large) {
        justify-content: start;
      }
      &__link {
        &:not(:hover) {
          color: var(--color-link)!important;
        }
      }
    }
    .page-footer__logo-link {
      display: block;
      text-align: center;
      margin-bottom: var(--space-small);
      @include core.named-breakpoint(medium) {
        text-align: left;
        margin-bottom: 0;
      }
    }
    > .base-margin-b:last-child {
      --margin-b-space: 0;
    }
  }

  .page-footer__contents {
    @include core.named-breakpoint(large) {
      grid-column-start: auto;
      grid-column-end: span 3;
      grid-row-end: 1;
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 300px auto;

    }

    .page-footer__contact {
      @include core.named-breakpoint(large) {
        grid-column: 1;
        display: flex;
        flex-direction: column;
        .page-footer-title {
          flex: 0 1 auto;
        }
        base-slide-content {
          flex: 1 1 auto;
          display: flex!important;
          flex-direction: column;
        }
      }

      .page-footer__contact-address {
        @include core.named-breakpoint(large) {
          flex: 1 1 auto;
        }
      }
      .page-footer__contact-infos {
        padding: var(--space-regular) 0;
        margin-bottom: var(--space-small);
        @include core.named-breakpoint(large) {
          padding: 0;
          margin-bottom: 0;
          flex: 0 1 auto;
        }
      }

      a {
        text-decoration: none;
        &:is(:hover, :active) {
          color: var(--color-contrast);
          text-decoration: underline;
        }
      }
    }
    .page-footer__sitemap {
      @include core.named-breakpoint(large) {
        grid-column: 2;
        display: flex;
        flex-direction: row;
        gap: 1.5rem;
        > div {
          flex: 1;
        }
      }
      base-slide-content {
        margin-bottom: var(--space-regular);
        @include core.named-breakpoint(large) {
          margin-bottom: 0;
        }
      }
      a {
        display: block;
        padding: 0.75rem 0;
        font-size: 0.9rem;
        &:first-child {
          padding-top: 0.7rem;
          @include core.named-breakpoint(large) {
            padding-top: 0.75rem;
          }
        }
        &:is(:hover, :focus) {
          text-decoration: underline;
        }
      }
    }
  }

  .page-footer__separator {
    @include core.named-breakpoint(large) {
      grid-column-start: auto;
      grid-column-end: span 4;
      grid-row-end: 2;
    }
    hr {
      margin-top: 0;
      border-top: 1px solid var(--color-link);
      border-left: none;
      border-right: none;
      border-bottom: none;
      // 1px solid rgb(212, 230, 247)
      margin-bottom: var(--space-big);

      @include core.named-breakpoint(large) {
        margin-top: var(--space-regular);
        margin-bottom: var(--space-tiny);
      }
    }
  }

  :is(.page-footer__note, .page-footer__links) {
    --color-link: var(--color-main);
    --color-text: var(--color-main);

    @include core.named-breakpoint(medium) {
      --color-main: #D4E6F7;
      --color-link: var(--color-main);
      --color-text: var(--color-main);
    }
    @include core.named-breakpoint(large) {
      grid-column-start: auto;
      grid-column-end: span 2;
      grid-row-end: 3;
    }
  }

  .page-footer__note {
    margin: var(--space-small) 0px;

    @include core.named-breakpoint(medium) {
      color: #D4E6F7;
    }
  }
  
  .page-footer__links {
    margin: var(--space-regular) 0px;
    @include core.named-breakpoint(large) {
      margin: var(--space-small) 0px;
      justify-content: end;
      display: flex;
      flex-direction: row;
      gap: 1.5rem;
    }
    a{
      padding: var(--space-small) 0;
      display: block;
      @include core.named-breakpoint(large) {
        padding: 0;
      }
      &:is(:hover, :focus) {
        text-decoration: underline;
      }
    }
  }
}

.page-footer-toggler {
  appearance: none;
  background: transparent;
  color: var(--color-contrast);
  border: 2px solid;
  border-radius: 100%;
  width: 1.5rem;
  height: 1.5rem;
  @include core.named-breakpoint(large) {
    display: none;
  }
}